import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Image } from "react-native";
import MainHeader from "../DonatePage/components/MainHeader";
import BackButton from "../PaymentPage/components/BackButton";
import { useContext } from "react";
import { GlobalContext } from "../../services/GlobalContext";

export default function AboutPage(){

    const { settings } = useContext(GlobalContext)

    //const text = `Nossa missão é proclamar o amor de Deus e o evangelho de Jesus Cristo, guiando pessoas a uma vida transformada pela fé, compaixão e serviço ao próximo. Buscamos ser uma comunidade acolhedora, onde cada indivíduo pode encontrar crescimento espiritual, apoio fraternal e um espaço para aprofundar sua relação com Deus. Com base nos ensinamentos bíblicos, promovemos a justiça, a paz e a esperança, impactando nossas famílias, nossa cidade e o mundo com a luz de Cristo.`

    return(
        <View>
            { settings && (
                <>
                    <MainHeader/>
                    <ScrollView style={styles.scroll}>
                            <View style={styles.content}>
                            <View>
                            <Text style={styles.title}>Sobre a Igreja</Text>
                            </View>
                            <View style={styles.mainContainer}>
                                <Text style={styles.title}>Nossa Missão</Text>
                                <Text style={styles.description}>{settings.settings[0].site_description}</Text>
                            </View>
                            <View>
                                <Text style={styles.title}>Contato</Text>
                            </View>
                            <View style={styles.iconContainer}>
                                <TouchableOpacity style={styles.icon}>
                                    <Image
                                        style={{width:22, height:22, resizeMode:'contain'}}
                                        source={{uri:'https://cdn-icons-png.flaticon.com/512/717/717392.png'}}
                                    />
                                    <Text>
                                        {settings.settings[0].social_network.instagram}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.icon}>
                                    <Image
                                        style={{width:22, height:22, resizeMode:'contain'}}
                                        source={{uri:'https://cdn-icons-png.flaticon.com/512/154/154858.png'}}
                                    />
                                    <Text style={{marginTop: 5}}>
                                        {settings.settings[0].social_network.whatsapp}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.icon}>
                                    <Image
                                        style={{width:22, height:22, resizeMode:'contain'}}
                                        source={{uri:'https://cdn-icons-png.flaticon.com/512/21/21155.png'}}
                                    />
                                    <Text>
                                        {settings.settings[0].social_network.facebook}
                                    </Text>
                                </TouchableOpacity>
                                </View>
                                <View style={styles.buttonContainer}>
                                    <BackButton/>
                                </View>
                                </View>
                            </ScrollView>
                </>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    scroll:{
        flexGrow:1
    },
    content:{
        alignItems: 'center',
        marginTop:40,
    },
    mainContainer: {
        paddingTop: 16,
        paddingBottom:40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#fff',
        elevation:3,
        margin:10,
        borderRadius:8,
        width:'80%',
        shadowColor: '#000',      
        shadowOffset: { width: 0, height: 2 },  
        shadowOpacity: 0.25,         
        shadowRadius: 3.84,   
    },
    title: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 18,
        margin: 5,
    },
    description: {
        color: '#000',
        margin: 5,
        fontSize: 16,
        textAlign:'center',
        padding:4,
    },
    dataContainer: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start', 
        width: '100%', 
        paddingHorizontal: 16, 
    },
    dataText: {
        color: '#000',
        margin: 5,
        fontSize: 16,
    },
    buttonContainer:{
        width:'40%'
    },
    iconContainer:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        marginBottom:20,
    },
    icon:{
        backgroundColor:'#fff',
        alignItems:'center',
        justifyContent:'center',
        margin:10,
        elevation:3,
        padding:20,
        borderRadius:16,
        shadowColor: '#000',      
        shadowOffset: { width: 0, height: 2 },  
        shadowOpacity: 0.25,         
        shadowRadius: 3.84,   
    }
});
