import { useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import axios from "axios";
import { GlobalContext } from "../../../services/GlobalContext";

export default function TitleSection(){
    
    const [verse, setVerse] = useState('O SENHOR é o meu pastor e nada me faltará')
    const [passage, setPassage] = useState('Salmos - 23:1')
    const {settings} = useContext(GlobalContext)


    async function generateVerse(){
        try {
            const {data} = await axios.get(`https://www.abibliadigital.com.br/api/verses/nvi/sl/random`)
            console.log(data)
            console.log(data.chapter, data.number)
            setVerse(data.text) 
            setPassage(`${data.book.name} - ${data.chapter}:${data.number}`)          
        } catch (error) {
            console.error(error)
        }
 
    }
    
    return(

            <View style={styles.container}>
                {settings && (
                    <>
                        <Image
                            style={{ width: 30, height: 30, resizeMode: 'contain' }} 
                            source={{ uri: 'https://cdn-icons-png.flaticon.com/512/89/89013.png' }}/>
                        <Text style={{color:'#000', fontWeight:'bold', textAlign:'center', fontSize: 18}}>{settings.settings[0].site_name}</Text>
                        <View>
                            <Text style={{color:'#000', textAlign:'center'}}>
                                "{verse}"
                            </Text>
                            <Text style={{color:'#000', textAlign:'center'}}>
                                {passage}
                            </Text>
                        </View>
                    </>

                )}
 
            </View>

    )
}

const styles = StyleSheet.create({
    container:{
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: '#fff',
        height:150,
        borderBottomLeftRadius:50,
        borderBottomRightRadius: 50,
        padding: 14,
        elevation:5,
        zIndex:4,
        shadowColor: '#000',        
        shadowOffset: { width: 0, height: 2 },  
        shadowOpacity: 0.25,        
        shadowRadius: 3.84,   
    }
})
