import { useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";

export default function DateFooter(){
    const [hour, setHour] = useState(getCurrentData())

    function getCurrentData() {
        const date = new Date()
        const dia = String(date.getDate()).padStart(2, '0')
        const mes = String(date.getMonth() + 1).padStart(2, '0')
        return `${dia}/${mes}`
    }

    return(
        <View style={styles.mainContainer}>
            <View style={{flexDirection:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                <View style={{justifyContent:'center', alignItems:'center', padding:8}}>
                    <Image
                    style={{tintColor:'#000', resizeMode:'contain', width:16, height:16, marginLeft: 10}}
                    source={{uri:'https://cdn-icons-png.flaticon.com/512/11677/11677498.png'}}
                    />
                </View>
                <Text style={{color:'#000', fontSize: 16, fontWeight: 'bold', marginRigth:10}}>{hour}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
        backgroundColor:'#fff',
        width:180,
        justifyContent:'center',
        alignItems:'center',
        height:45,
        borderTopLeftRadius:16,
        borderTopRightRadius:16,
        elevation: 6,                       // Aumenta a elevação para uma sombra mais intensa
        shadowColor: '#000',               // Cor da sombra
        shadowOffset: { width: 0, height: 4 },  // Deslocamento mais pronunciado
        shadowOpacity: 0.4,                // Aumenta a opacidade da sombra
        shadowRadius: 6,    


    }
})