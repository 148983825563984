import { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";
import { KeyboardContext } from "../context/NumercKeyboardContext"

export default function ValueLabel({ tel, isSmallScreen }) {
    
    const { valor } = useContext(KeyboardContext);

    function formatValue() {
        if (valor.length === 1) {
            return `0,0${valor}`;
        }
        if (valor.length === 2) {
            return `0,${valor}`;
        }
        if (valor.length >= 3) {
            const notDecimalPart = valor.slice(0, valor.length - 2);
            const decimalPart = valor.slice(valor.length - 2);
            return `${notDecimalPart},${decimalPart}`;
        }
    }

    console.log(tel); // Verifica se 'tel' está sendo passado corretamente

    const styles = StyleSheet.create({
        mainContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent:'center',
            borderStyle: 'solid',
            borderColor: '#000',
            backgroundColor: "#fff",
            borderWidth: 1,
            borderRadius: 8,
            width: '100%',
            minWidth: isSmallScreen? 300 : 390,
            height: isSmallScreen ? 50 : 70,
            marginBottom: 10,
            elevation: 3,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,  
        },
        BRLcontainer: {
            width: '20%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRightWidth: 1,
            borderStyle: 'solid',
            borderColor: '#000',
            height: isSmallScreen ? 50 : 70,
        },
        numberContainer: {
            width: tel ? '100%' : '80%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: tel ? 0 : '10%',
        },
    });
    
    return (
        <View style={styles.mainContainer}>
            {!tel && (
                <View style={styles.BRLcontainer}>
                    <Text style={{ color: '#000', fontSize:20, }}>R$</Text>
                </View>
            )}
            <View style={styles.numberContainer}>
                {!tel ? (
                    <Text style={{ color: '#000', fontSize:20, }}>
                        {formatValue() || '0,00'}
                    </Text>
                ) : (
                    <Text style={{ color: '#000' }}>
                        {valor}
                    </Text>
                )}
            </View>
        </View>
    );
}
