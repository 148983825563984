import { StyleSheet, TouchableOpacity, Image } from "react-native";
// import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
// import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { KeyboardContext } from "../context/NumercKeyboardContext"


export default function DeleteButton({isSmallScreen}){
    const {setValor} = useContext(KeyboardContext)
    
    function deleteNumber(){
        setValor('')
    }

    const styles = StyleSheet.create({
        buttonContainer:{
            backgroundColor:'#F35D49',
            padding: 14,
            borderRadius: 8,
            width: isSmallScreen ? '80%' : '100%',
            minWidth: isSmallScreen ? 90 : 124,
            height: isSmallScreen ? 60 : 90,
            alignItems: 'center',
            justifyContent: 'center',
            margin:5,
            elevation:5,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,  
            
        }
    })
    
    return(
        <TouchableOpacity 
        onPress={deleteNumber}
        style={styles.buttonContainer}>
            <Image
                style={{width:22, height:22, resizeMode:'contain', tintColor:'white'}}
                source={{uri:'https://cdn-icons-png.flaticon.com/512/8078/8078306.png'}}
            />
            
            {/* <FontAwesomeIcon icon={faDeleteLeft} size={22}/> */}
        </TouchableOpacity>
    )
}

