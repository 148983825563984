import { View, Text, Image, StyleSheet, TouchableOpacity, Linking } from "react-native";
import MainHeader from "../DonatePage/components/MainHeader";
import BackButton from "../PaymentPage/components/BackButton";

export default function RegisterPage(){

    function goToBiblie(){
        Linking.openURL('https://api-totem.pacsafe.com.br/usuarios/login')
    }

    return(
        <View style={{flex: 1}}>
            <MainHeader/>
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                <View style={{margin:40}}>
                    <View style={{marginBottom:10, alignItems:'center', justifyContent:'center'}}>
                        <Text style={{color:'#000', fontWeight: 'bold', fontSize:16 }}>Leia o código QR para se registrar</Text>
                    </View>
                    <View style={{justifyContent:'center', alignItems:'center'}}>
                        <Image
                        style={{width: 200, height: 200, resizeMode: 'contain',}}
                        source={{uri:'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://api-totem.pacsafe.com.br/usuarios/login'}}
                        />
                    </View>
                </View>
          
                  
                <BackButton/>
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    clipBoard: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        margin:20,
        marginBottom: 40,
        borderWidth: 1,  
        borderColor: '#ccc',  
        borderRadius: 5
    },
    qrCodeText: {
        color: "#000",
        width: 200,
        overflow: 'hidden', 
    },
})