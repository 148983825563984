import { createContext, useState } from "react";

export const KeyboardContext = createContext()

export function KeyboardContextProvider({children}){
    const [valor, setValor] = useState('')
    return(
        <KeyboardContext.Provider value={{valor, setValor}}>
            {children}
        </KeyboardContext.Provider>
    )

}