import { Image, StyleSheet, Text, View } from "react-native";
import BackButton from "../PaymentPage/components/BackButton";
import MainHeader from "../DonatePage/components/MainHeader";

export default function ConfirmPage(){


    return(
        <View style={styles.mainContainer}>
            <View style={styles.headerContainer}>
                <MainHeader
                showButtons={false}
                goToHome={true}
                />
            </View>

            <View >
               <Image
                source={require('../../assets/DonatePage.png')}
                style={styles.imageContainer}
                resizeMode="contain"
               />
            </View>
            <View style={{paddingBottom:20}}>
                <Text style={{color:'#000', fontSize:16, marginLeft:7,}}>
                    Sua doação foi aprovada com sucesso
                </Text>
                <Text style={{color:'#000', fontSize:20}}>
                    Muito obrigado pela contribuição!
                </Text>
            </View>


            <View style={styles.buttonContainer}>
                    <BackButton/>
                </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        alignItems: 'center',
    },
    buttonContainer:{
        marginTop:20,
    },
    imageContainer:{
        width:400,
        height:300,
    },
    headerContainer:{
        width:'100%',
    }
})