import { createContext, useState } from "react";

export const GlobalContext = createContext()

export function GlobalContextProvider({children}){
    const [valor, setValor] = useState('')
    const [qrCode, setQrCode] = useState('')
    const [qrCode64, setQrCode64] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [paymentType, setPaymentType] = useState('')
    const [orderId, setOrderId] = useState('')
    const [settings, setSettings] = useState()

    return(
        <GlobalContext.Provider value={{
            valor, 
            setValor, 
            qrCode, 
            setQrCode,
            qrCode64,
            setQrCode64,
            orderNumber,
            setOrderNumber,
            paymentType,
            setPaymentType,
            orderId,
            setOrderId,
            settings,
            setSettings
        }}>
            {children}
        </GlobalContext.Provider>
    )

}