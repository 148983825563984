import { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image } from "react-native";

export default function ClockFooter(){
    const [hour, setHour] = useState(getCurrentTime())

    function getCurrentTime() {
        const date = new Date()
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        return `${hours}:${minutes}`
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setHour(getCurrentTime())
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    return(
        <View style={styles.mainContainer}>
            <View style={{flexDirection:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                <View style={{justifyContent:'center', alignItems:'center', padding:8}}>
                    <Image
                    style={{tintColor:'#000', resizeMode:'contain', width:16, height:16}}
                    source={{uri:'https://cdn-icons-png.flaticon.com/512/3311/3311693.png'}}
                    />
                </View>
                
                <Text style={{color:'#000', fontSize: 16, fontWeight: 'bold'}}>{hour}</Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
        backgroundColor:'#fff',
        width:180,
        justifyContent:'center',
        alignItems:'center',
        height:45,
        borderTopLeftRadius:16,
        borderTopRightRadius:16,
        elevation: 6,                       // Aumenta a elevação para uma sombra mais intensa
        shadowColor: '#000',               // Cor da sombra
        shadowOffset: { width: 0, height: 4 },  // Deslocamento mais pronunciado
        shadowOpacity: 0.4,                // Aumenta a opacidade da sombra
        shadowRadius: 6,    


    }
})