import { View, Text, StyleSheet } from "react-native";
import MainHeader from "../DonatePage/components/MainHeader";
import BackButton from "../PaymentPage/components/BackButton";

export default function Details({route}) {
    const { titulo, descricao, data, hora } = route.params;

    return (
        <View>
            <MainHeader/>
            <View style={styles.content}>
                <View style={styles.mainContainer}>
                    <Text style={styles.title}>{titulo}</Text>
                    <Text style={styles.description}>{descricao}</Text>

                    <View style={styles.dataContainer}>
                        <Text style={styles.dataText}>Data: {data}</Text>
                        <Text style={styles.dataText}>Hora: {hora}</Text>
                    </View>
                </View>
                <View style={styles.buttonContainer}>
                    <BackButton/>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    content:{
        alignItems: 'center'
    },
    mainContainer: {
        paddingTop: 16,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:'#fff',
        elevation:3,
        margin:10,
        borderRadius:8,
        width:'80%',
        shadowColor: '#000',         
        shadowOffset: { width: 0, height: 2 },  
        shadowOpacity: 0.25,         
        shadowRadius: 3.84, 
        paddingBottom:40,
    },
    title: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: 18,
        margin: 5,
    },
    description: {
        color: '#000',
        margin: 5,
        fontSize: 16,
        textAlign:'center',
        padding:4,
    },
    dataContainer: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start', 
        width: '100%', 
        paddingHorizontal: 16, 
    },
    dataText: {
        color: '#000',
        margin: 5,
        fontSize: 16,
    },
    buttonContainer:{
        width:'40%'
    }
});
