import React from 'react'
import HomePage from './pages/HomePage/HomePage'
import DonatePage from './pages/DonatePage/DonatePage'
import PaymentTypes from './pages/PaymentTypes/PaymentTypes'
import PaymentPage from './pages/PaymentPage/PaymentPage'
import ConfirmPage from './pages/ConfirmPage/ConfirmPage'
import NewsPage from './pages/NewsPage/NewsPage'
import Details from './pages/NewsPage/Details'
import CalendarPage from './pages/CalendarPage/CalendarPage' 
import RegisterPage from './pages/RegisterPage/RegisterPage'
import AboutPage from './pages/AboutPage/AboutPage'
import BiblePage from './pages/BiblePage/BiblePage'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { GlobalContextProvider } from './services/GlobalContext'

export default function App() {
  const Stack = createNativeStackNavigator()

  return (
    <GlobalContextProvider>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name="Home"
            component={HomePage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Donate"
            component={DonatePage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PaymentTypes"
            component={PaymentTypes}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="PaymentPage"
            component={PaymentPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="ConfirmPage"
            component={ConfirmPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="NewsPage"
            component={NewsPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Details"
            component={Details}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="CalendarPage"
            component={CalendarPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="RegisterPage"
            component={RegisterPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="About"
            component={AboutPage}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Biblia"
            component={BiblePage}
            options={{
              headerShown: false,
            }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </GlobalContextProvider>
  )
}