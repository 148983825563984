import { useContext } from "react";
import { TouchableOpacity, Text, StyleSheet, Alert } from "react-native";
import { KeyboardContext } from "../context/NumercKeyboardContext"
import { useNavigation } from "@react-navigation/native";
import { GlobalContext } from "../../../services/GlobalContext";

export default function ConfirmButton({tel, isSmallScreen}){
    
    const {valor} = useContext(KeyboardContext)
    const {setValor} = useContext(GlobalContext)
    const {navigate} = useNavigation()

    function convertToFloat(){
        const floatedValue = parseFloat(valor.slice(0, -2) + '.' + valor.slice(-2))
        return floatedValue 
    }

    function validateValue(){

        if(!valor){
            Alert.alert('Valor inválido', 
                'Insira um algum valor.',             
                [
                { 
                  text: "OK", 
                }
              ])
              return            
        }

        if(valor.length <= 8 && tel){
            Alert.alert('Número inválido', 
                'Digite um número de telefone válido',             
                [
                { 
                  text: "OK", 
                }
              ])
              return
        }   

        if(tel){
            Alert.alert('Aguarde', 
                'Seu Numero está sendo cadastrado',             
                [
                { 
                  text: "OK", 
                }
              ])
              return
        }

        if(valor.length <= 2 && !tel){
            Alert.alert('Valor inválido', 
                'Valor abaixo de R$ 1,00',             
                [
                { 
                  text: "OK", 
                }
              ])

            return
        }     
        setValor(`${convertToFloat()}`)
        navigate('PaymentTypes')   

    }

    const styles = StyleSheet.create({
        mainContainer:{
            backgroundColor:'#00ED51',
            alignItems: 'center',
            justifyContent:'center',
            margin:10,
            width:'100%',
            minWidth: isSmallScreen? 300 : 390,
            height: isSmallScreen? 60 : 90,
            borderRadius: 8,
            elevation:5,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,  
        }
    })
    
    return(
        <TouchableOpacity
        onPress={() => {
            validateValue()
        }}
        style={styles.mainContainer}>
            <Text style={{color:'#fff', fontWeight:'bold'}}>
                CONFIRMAR
            </Text>
        </TouchableOpacity>
        
    )
}

