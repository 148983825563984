import { useContext, useEffect, useState } from "react";
import NumericKeyboard from "../../NumericKeyboard/NumericKeyboard";
import Products from "../../Products/Products";
import { StyleSheet, View } from "react-native";
import MainHeader from "./MainHeader";
import { DonatePageContext } from "../context/DonatePageContext";

export default function DonatePageContent(){
    
    const {type} = useContext(DonatePageContext)

    return(
        <View style={styles.mainContainer}>
        <MainHeader
        showButtons={true}/>
    {type === 'TECLADO' && (
        <NumericKeyboard
        tel={false}/>
    )}
    {type === 'PRODUTOS' && (
        <Products/>
    )}
    </View>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
        flex:1,
    }
})