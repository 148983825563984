import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from "react-native";
import axios from "axios";
import { useContext } from "react";
import { GlobalContext } from "../../../services/GlobalContext";
import { useNavigation } from "@react-navigation/native";
import { useState, useEffect } from 'react'


export default function PaymentTypeContainer({icon, title, onClick, paymentType}){
    
    const {valor, setOrderNumber, setQrCode, setQrCode64, setPaymentType, setOrderId} = useContext(GlobalContext)
    const {navigate} = useNavigation()
    const [isSmallScreen, setIsSmallScreen] = useState(false)

    useEffect(() => {
        const updateLayout = () => {
          const screenWidth = Dimensions.get("window").width
          setIsSmallScreen(screenWidth < 768) 
        }
    
        updateLayout()
    
        const subscription = Dimensions.addEventListener("change", updateLayout)
    
        return () => {
          subscription?.remove()
        }
      }, [])

    function bodyContructor(){
        
        if(paymentType === 'pix'){

            const body = {
                preco: parseFloat(valor),
                email: 'gustavovni08@gmail.com',
                description: 'PIX gerado na API'
            }

            return body
        }

        if(paymentType === 'debit'){
            const body = {
                amount: parseFloat(valor),
                description: 'Contribuição Débito'
            }
            return body
        }

        if(paymentType === 'credit'){
            const body = {
                amount: parseFloat(valor),
                description:'Contribuição Crédito',
                //installments:1
            }
            return body
        }

    }

    async function postPaymentOrder(){
        onClick()
        
        const body = bodyContructor()
        console.log(body)

        if(paymentType === 'pix'){
            try {
                const {data} = await axios.post('https://api-totem.pacsafe.com.br/api/checkout', body)
                setOrderNumber(data.order.number)
                setQrCode(data.order.qr_code)
                setQrCode64(data.payment.qr_code)
                setPaymentType(data.order.meio_contribuicao)
                setOrderId(data.order.id)
                console.log(data)
                navigate('PaymentPage')            
                return  
            } catch (error) {
                console.error(error.message)
            }

        }

        if(paymentType === 'debit'){
            try {
                const {data} = await axios.post('https://api-totem.pacsafe.com.br/api/send-payment-debit', body)
                setOrderNumber(data.order.number)
                setQrCode('')
                setQrCode64('')
                setPaymentType(data.order.meio_contribuicao)
                setOrderId(data.order.id)
                console.log(data)
                navigate('PaymentPage')     
                return
            } catch (error) {
                console.error(error.message)
            }

        }

        if(paymentType === 'credit'){
            try {
                const {data} = await axios.post('https://api-totem.pacsafe.com.br/api/send-payment-credit', body)
                setOrderNumber(data.order.number)
                setQrCode('')
                setQrCode64('')
                setPaymentType(data.order.meio_contribuicao)
                setOrderId(data.order.id)
                console.log(data)
                navigate('PaymentPage')     
                return
            } catch (error) {
                console.error(error.message)
            }

        }

    }

    const styles = StyleSheet.create({
        mainContainer:{
            backgroundColor:'#fff',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            paddingRight: 20,
            paddingLeft: 20,
            margin: 10,
            height: 140,
            borderRadius:8,
            width: isSmallScreen ? '80%' : '50%',
            elevation:3,
            shadowColor: '#000',      
            shadowOffset: { width: 0, height: 2 },  
            shadowOpacity: 0.25,         
            shadowRadius: 3.84,   
        }
    })
   
    return(
        <TouchableOpacity 
        onPress={postPaymentOrder}
        style={styles.mainContainer}>
            <View style={{paddingLeft:isSmallScreen ? 40 : 80}}>
                {icon}
            </View>
            <View style={{width: '60%', justifyContent:'flex-start'}}>
                <Text style={{color: '#1A83E3', fontSize: 32, fontWeight: 'bold'}}>
                    {title}
                </Text>
            </View>
        </TouchableOpacity>
    )
}

