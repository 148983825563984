import { View, Text, Image } from "react-native";
import MainHeader from "../DonatePage/components/MainHeader";
import BackButton from "../PaymentPage/components/BackButton";

export default function BiblePage(){

    return(
        <View style={{flex: 1}}>
            <MainHeader/>
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                <View style={{margin:40}}>
                    <View style={{marginBottom:10, alignItems:'center', justifyContent:'center'}}>
                        <Text style={{color:'#000', fontWeight: 'bold', fontSize:16 }}>Leia o código QR para acessar a bÍblia</Text>
                    </View>
                    <View style={{justifyContent:'center', alignItems:'center'}}>
                        <Image
                        style={{width: 200, height: 200, resizeMode: 'contain',}}
                        source={{uri:'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://biblia-online.pacsafe.com.br/'}}
                        />
                    </View>
                </View>       
                  
                <BackButton/>
            </View>

        </View>
    )
}

