import { StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { useNavigation } from "@react-navigation/native"


export default function EventCard({titulo, descricao, data, hora}){

    const {navigate} = useNavigation()
    

    function navigateToDetails(){
        const event = {
            titulo,
            descricao,
            data,
            hora
        }
        console.log(event)
        navigate('Details', event)
    }

    return(
        <TouchableOpacity style={styles.mainContainer}
        onPress={navigateToDetails}>
            <View style={styles.titleContainer}>
                <View style={styles.iconContainer}>
                    {/* <FontAwesomeIcon icon={faCalendar} /> */}
                </View>
                <View>
                    <Text style={styles.titleFont}>{titulo}</Text>
                </View>
            </View>
            <View style={styles.descriptionContainer}>
                <Text style={styles.textFont}>{descricao}</Text>
            </View>
            <View style={styles.dataContainer}>
                <View style={styles.sectionContainer}>
                    <Text style={styles.font}>Data:</Text>
                    <Text style={styles.font}>{data}</Text>
                </View>
                <View style={styles.sectionContainer}>
                    <Text style={styles.font}>Hora:</Text>
                    <Text style={styles.font}>{hora}</Text>
                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:'#fff',
        borderRadius:8,
        elevation:3,
        width:300,
        minWidth:300,
        margin:10,
        padding:8,
        shadowColor: '#000',         // Cor da sombra
        shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
        shadowOpacity: 0.25,         // Opacidade da sombra
        shadowRadius: 3.84, 
    },
    titleFont:{
        color:'#000',
        fontWeight:'bold',
        fontSize: 16,
    },
    font:{
        color:'#000'
    },
    textFont:{
        color:'#000',
        textAlign:'center',
    },
    iconContainer:{
        justifyContent:'flex-start',
        marginRight: 8, // Adiciona um espaçamento entre o ícone e o texto
    },
    titleContainer:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        margin:8,
        padding:8,
    },
    descriptionContainer:{
        width:250,
    },
    textContainer:{
        margin:4,
    },
    dataContainer:{
        justifyContent:'center',
        alignItems:'center',
        marginTop:4,
    },
    sectionContainer:{
        flexDirection:'row',
        justifyContent:'space-between',
        width:'100%',
        margin:4,
    }
});
