import { ScrollView, StyleSheet, Text, View, Dimensions } from "react-native"
import ValueLabel from "./components/ValueLabel"
import NumberButton from "./components/NumberButton"
import DeleteButton from "./components/DeleteButton"
import ConfirmButton from "./components/ConfirmButton"
import { KeyboardContextProvider } from "./context/NumercKeyboardContext"
import { useState, useEffect } from "react"

export default function NumericKeyboard({tel}){
    
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
      const updateLayout = () => {
        const screenWidth = Dimensions.get("window").width;
        setIsSmallScreen(screenWidth < 768); // Definindo o breakpoint para telas pequenas
      };
  
      updateLayout();
  
      const subscription = Dimensions.addEventListener("change", updateLayout);
  
      return () => {
        subscription?.remove();
      };
    }, []);

    const styles = StyleSheet.create({
        mainContainer:{
            justifyContent:'center',
            alignItems:'center',
            margin: 40,
        },
        lineSection:{
            flexDirection:'row',
            width:'9%',
            alignItems:'center',
            justifyContent:'center'
        },
        headerContainer:{
            width:'20%',
            marginBottom:40,
        },
        title:{
            color:'#000',
            fontSize:20,
            fontWeight:'bold',
            marginBottom:20,
        },
    })
    
    
    return(
        <KeyboardContextProvider>
            <ScrollView>
                <View style={styles.mainContainer}>
                    {!tel && (
                        <Text style={styles.title}>Digite o valor da sua Doação</Text>
                    )}
                <View style={{width:isSmallScreen ? '10%' :'29%', justifyContent:'center', alignItems:'center'}}>
                    <ValueLabel
                    isSmallScreen={isSmallScreen}
                    tel={tel}/>
                </View>
                <View style={styles.lineSection}>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'1'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'2'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'3'}/>
                </View>
                <View style={styles.lineSection}>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'4'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'5'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'6'}/>
                </View>
                <View style={styles.lineSection}>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'7'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'8'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'9'}/>
                </View>
                <View style={styles.lineSection}>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'0'}/>
                    <NumberButton
                    isSmallScreen={isSmallScreen}
                    value={'00'}/>
                    <DeleteButton
                    isSmallScreen={isSmallScreen}/>
                </View>
                <View style={{alignItems:'center', justifyContent:'center',width: isSmallScreen ? '10%' :'29%'}}>
                    <ConfirmButton 
                    isSmallScreen={isSmallScreen}
                    tel={tel}/>
                </View>
                </View>
            </ScrollView>
        </KeyboardContextProvider>
        
    )
}

