import { useContext } from "react";
import { Text, StyleSheet, TouchableOpacity } from "react-native";
import { KeyboardContext } from "../context/NumercKeyboardContext"

export default function NumberButton({value, isSmallScreen}){

    const {valor, setValor} = useContext(KeyboardContext)
    
    function addNumber(){
        if(!valor && value.includes('0') || valor.length >= 12){
            return
        }
        const newValue = `${valor}` + `${value}`
        setValor(newValue) 
        console.log(valor, value)
    }

    const styles = StyleSheet.create({
        buttonContainer:{
            backgroundColor:'#1A83E3',
            padding: 14,
            borderRadius: 8,
            width: isSmallScreen ? '80%' : '100%',
            minWidth: isSmallScreen ? 90 : 124,
            height: isSmallScreen ? 60 : 90,
            alignItems: 'center',
            justifyContent: 'center',
            margin:5,
            elevation:5,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,  
            
        }
    })

    return(
        <TouchableOpacity 
        onPress={addNumber}
        style={styles.buttonContainer}>
            <Text style={{color:'#fff', fontWeight: 'bold', fontSize:22,}}>{value}</Text>
        </TouchableOpacity>
    )
}

