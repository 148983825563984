import { View, ScrollView, StyleSheet, Text,  Dimensions, ActivityIndicator  } from "react-native";
import MainHeader from "../DonatePage/components/MainHeader";
import EventCard from "./components/EventCard";
import { useState, useEffect } from "react";
import axios from "axios";

export default function NewsPage(){

    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [agendas, setAgendas] = useState()

    useEffect(() => {
      const updateLayout = () => {
        const screenWidth = Dimensions.get("window").width
        setIsSmallScreen(screenWidth < 768) 
      }
  
      updateLayout()
  
      const subscription = Dimensions.addEventListener("change", updateLayout)
  
      return () => {
        subscription?.remove()
      }
    }, [])

    function chunkArray(array, size) {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    
    async function getEvents(){
        try {
            const {data} = await axios.get('https://api-totem.pacsafe.com.br/api/novidades')
            console.log(data)
            return data            
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        async function getData(){
            const data = await getEvents()
            setAgendas(data.novidades)
            setIsLoading(false)
            console.log(data)
        }

        getData()
    }, [])

 
    function formatarData(dataISO) {
        const [ano, mes, dia] = dataISO.split('-');
        return `${dia}/${mes}/${ano}`;
      }

    const styles = StyleSheet.create({
        container: {
            flex: 1,
        },
        scrollContent: {
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 16,
        },
        mainContainer: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        row: {
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-around', // Espaçamento entre os itens
            marginBottom: 16, // Espaçamento entre as linhas
        },
        wrapper: {
            marginTop: 20,
        },
        loadingScreen: {
            ...StyleSheet.absoluteFillObject,
            zIndex: 4,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            alignItems: "center",
            justifyContent: "center",
          },
    });


    return (
        <View style={styles.container}>
            <MainHeader showButtons={false} />
            <View style={styles.mainContainer}>
            {isLoading && (
                <View style={styles.loadingScreen}>
                    <ActivityIndicator size="large" color="#fff" />
                </View>
            )}

            {!isLoading && (
                <>
                    <ScrollView contentContainerStyle={styles.scrollContent}>
                        {agendas && chunkArray(agendas, 3).map((row, rowIndex) => (
                            <View key={rowIndex} style={styles.row}>
                                {row.map((item, index) => (
                                    <EventCard
                                        key={index}
                                        titulo={item.body.split('<li>')[1].slice(0, -10)}
                                        descricao={item.body.split('<li>')[1].slice(0, -10)}
                                        data={formatarData(item.published_at.split(' ')[0])}
                                        hora={item.published_at.split(' ')[1]}
                                    />
                                ))}
                            </View>
                        ))}
                        <View style={styles.wrapper}>
                            <Text></Text>
                        </View>
                    </ScrollView>
                </>
            )}

            </View>
        </View>
    )
}


