import { useContext } from "react"
import { Text, View, TouchableOpacity, StyleSheet } from "react-native"
import { DonatePageContext } from "../context/DonatePageContext"

export default function ModeButton({isSelected, value}){
    
    const {setType, type} = useContext(DonatePageContext)
    
    function setMode(){
        console.log(type)
        setType(value)
    }

    return(
        <TouchableOpacity 
        onPress={setMode}
        style={styles.mainContainer}>
            <View style={styles.titleContainer}>
                <Text style={{color:'#000'}}>
                    {value}
                </Text>                
            </View>
            {isSelected && (
                <View style={styles.wrapper}><Text></Text></View>
            )}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
        backgroundColor: `#fff`,
        justifyContent:'space-between',
        alignItems:'center',
        width:'50%',
    },
    titleContainer:{
        height:70,
        justifyContent:'center'
    },
    wrapper:{
        backgroundColor:'#1A83E3',
        height:1,
        width:'100%',
    }

})