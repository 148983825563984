import { useNavigation } from "@react-navigation/native";
import { Text, StyleSheet, TouchableOpacity } from "react-native";

export default function BackButton(){
    
    const {navigate} = useNavigation()
    
    return(
        <TouchableOpacity 
        onPress={() => {navigate('Home')}}
        style={styles.mainContainer}>
            <Text style={{color:'#fff', fontWeight:'bold'}}>
                Voltar ao início
            </Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
        backgroundColor:'#1A83E3',
        padding: 14,
        borderRadius: 8,
        alignItems:'center',
        justifyContent:'center',
        elevation: 5,
        shadowColor: '#000',         // Cor da sombra
        shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
        shadowOpacity: 0.25,         // Opacidade da sombra
        shadowRadius: 3.84,  

    }
})