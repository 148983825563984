import { View, StyleSheet, ScrollView, Dimensions, Image, ImageBackground } from 'react-native'
import NavigateButton from './components/NavigateButton'
import TitleSection from './components/TitleSection'
import ClockFooter from './components/ClockFooter';
import DateFooter from './components/DataFooter';
import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { GlobalContext } from '../../services/GlobalContext';

export default function HomePage() {

    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const {setSettings, settings} = useContext(GlobalContext)

    async function getSettings () {
        try {
            const {data} = await axios.get('https://api-totem.pacsafe.com.br/api/general-settings')
            console.log(data)
            return data            
        } catch (error) {
            console.error(error)
        }

    }

    useEffect(() => {
        
        const getData = async () => {
            const data = await getSettings()
            setSettings(data)
        }

        getData()

    }, [setSettings])

    useEffect(() => {
      const updateLayout = () => {
        const screenWidth = Dimensions.get("window").width
        setIsSmallScreen(screenWidth < 768) // Definindo o breakpoint para 768px, ajuste conforme necessário
      }
  
      updateLayout()
  
      // Adiciona o listener para mudanças de dimensão
      const subscription = Dimensions.addEventListener("change", updateLayout)
  
      // Limpa o listener quando o componente for desmontado
      return () => {
        subscription?.remove()
      }
    }, []);

    const styles = StyleSheet.create({
        container: {
            flex: 1,
    
        },
        scrollViewContainer: {
            flexGrow: 1, 
            justifyContent: 'center',
            alignItems: 'center',
            paddingVertical: 20,

        },
        mainContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
          sectionContainer: {
            width:'60%',
            margin: isSmallScreen ? 0 : 40,
          },
          sectionWrapper: {
            flexDirection: isSmallScreen ? 'column' : "row",
            alignItems: isSmallScreen ? 'flex-start' :'center',
            justifyContent:'center'
          },
          clockFooterContainer: {
            flexDirection:'row',
            position: 'absolute', 
            bottom:1,
            width: '100%', 
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            backgroundColor: 'transparent', 
            zIndex: 4, 
        },
        backgroundImage: {
            flex: 1,
            resizeMode: 'cover',
        },
    })

    //console.log(settings.settings[0].more_configs.plano_de_fundo)

    return (
        <>
            {settings && (
                        <ImageBackground
                        style={styles.backgroundImage}
                        source={{uri:settings.settings[0].more_configs.plano_de_fundo}}>
                                        <View style={styles.container}>
                                <TitleSection/>
                                <ScrollView contentContainerStyle={styles.scrollViewContainer}>
                                   <View style={styles.sectionWrapper}>
                                        <View style={styles.sectionContainer}>
                                            <NavigateButton
                                            text={'Sua oferta é um ato de amor e de compromisso com a obra de Deus e com a igreja do Senhor. '}
                                            icon={
                                            <Image
                                            style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                            source={{uri:'https://cdn-icons-png.flaticon.com/512/4689/4689030.png'}}/>
                                            }
                                            label='Contribua'
                                            value='Donate'
                                            isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                            text={'Conheça um pouco mais sobre os projetos que a igreja está envolvida e faça parte da mudança!'}
                                            icon={
                                            <Image
                                            style={{width: isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                            source={{uri:'https://cdn-icons-png.flaticon.com/512/11677/11677498.png'}}/>
                                            }
                                            label='Eventos'
                                            value='CalendarPage'
                                            isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                            text={'Acompanhe nossas últimas novidades sobre eventos, artigos e notícias.'}
                                            icon={
                                            <Image
                                            style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                            source={{uri:'https://cdn-icons-png.flaticon.com/512/78/78875.png'}}/>
                                            }
                                            label='Notícias'
                                            value='NewsPage'
                                            isSmallScreen={isSmallScreen}
                                            />
                                        </View>
                                        <View style={styles.sectionContainer}>
                                            <NavigateButton
                                            text={'Conheça um pouco mais sobre a nossa programação.'}
                                                icon={
                                                <Image
                                                style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                                source={{uri:'https://cdn-icons-png.flaticon.com/512/1946/1946429.png'}}/>
                                                }
                                                label='Conecte-se'
                                                // fontSize={14}
                                                value='RegisterPage'
                                                isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                                text={'Preservamos o cuidado mútuo entre nossa instituição e a nossa comunidade!'}
                                                icon={
                                                <Image
                                                style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                                source={{uri:'https://cdn-icons-png.flaticon.com/512/8644/8644718.png'}}/>
                                                }
                                                label='Sobre nós'
                                                value='About'
                                                // fontSize={14}
                                                isSmallScreen={isSmallScreen}
                                            />
                
                                            <NavigateButton
                                            text={'Explore a Bíblia como uma fonte de luz e orientação espiritual.'}
                                                icon={
                                                <Image
                                                style={{width:isSmallScreen? 20 : 32, height:isSmallScreen? 20 : 32, resizeMode:'contain', tintColor:'white'}}
                                                source={{uri:'https://cdn-icons-png.flaticon.com/512/1511/1511384.png'}}/>
                                                }
                                                label='Biblia'
                                                value='Biblia'
                                                isSmallScreen={isSmallScreen}
                                            />
                                        </View>
                                    </View>
                                    <View>
                                        {/* <Video
                                        source={require('../../assets/video.mp4')}
                                        /> */}
                                    </View>
                                </ScrollView>
                                {!isSmallScreen && (
                                    <View style={styles.clockFooterContainer}>
                                        <DateFooter/>
                                        <ClockFooter/>
                                    </View>
                                )}
                
                            </View>
                        </ImageBackground>
            )}
        </>


    )
}

