import { StyleSheet, View, ActivityIndicator, Dimensions, Image, Text } from "react-native"
import PaymentTypeContainer from "./components/PaymentsTypesContainer"
import MainHeader from "../DonatePage/components/MainHeader"
import { useEffect, useState } from "react"
import { useIsFocused } from "@react-navigation/native"


export default function PaymentTypes(){

    const [isLoading, setIsLoading] = useState(false)
    const isFocused = useIsFocused()

    const [isSmallScreen, setIsSmallScreen] = useState(false)

    useEffect(() => {
        const updateLayout = () => {
          const screenWidth = Dimensions.get("window").width
          setIsSmallScreen(screenWidth < 768) 
        }
    
        updateLayout()
    
        const subscription = Dimensions.addEventListener("change", updateLayout)
    
        return () => {
          subscription?.remove()
        }
      }, [])

    function activeLoading(){
        setIsLoading(true)
    }

    useEffect(() => {
        if(isFocused){
            setIsLoading(false)
        }
    }, [isFocused])

    return(
        <View style={styles.mainContainer}>
            <View>
                <MainHeader
                showButtons={false}/>
            </View>
            {isLoading && (
                <View style={styles.loadingScreen}>
                    <ActivityIndicator size="large" color="#fff" />
                </View>
            )}



            <View style={styles.PaymentTypesContainer}>
                
                <View style={{margin:40}}>
                    <Text style={{color:'#000', fontWeight:'bold', fontSize:20}}>Escolha um meio de contribuição.</Text>
                </View>
                
                <PaymentTypeContainer                
                title="PIX"
                icon={
                    <Image
                    style={{width:40, height:40, resizeMode:'contain', tintColor:'#1A83E3'}}
                    source={{uri:'https://cdn-icons-png.flaticon.com/512/714/714390.png'}}/>
                }
                paymentType="pix"
                onClick={activeLoading}
                />
                {!isSmallScreen && (
                    <>
                        <PaymentTypeContainer
                        icon={
                            <Image
                            style={{width:40, height:40, resizeMode:'contain', tintColor:'#1A83E3'}}
                            source={{uri:'https://cdn-icons-png.flaticon.com/512/4492/4492772.png'}}/>
                        }
                        title="CARTÃO DE DÉBITO"
                        paymentType="debit"
                        onClick={activeLoading}
                        />
                        <PaymentTypeContainer
                        icon={
                            <Image
                            style={{width:40, height:40, resizeMode:'contain', tintColor:'#1A83E3'}}
                            source={{uri:'https://cdn-icons-png.flaticon.com/512/4492/4492772.png'}}/>
                        }
                        title="CARTÃO DE CRÉDITO"
                        paymentType="credit"
                        onClick={activeLoading}
                        />                    
                    </>

                )}
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    mainContainer:{
       flex:1,
    },

    PaymentTypesContainer:{
        alignItems: 'center',
        justifyContent:'center',
        flex:1,
    },

    loadingScreen: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 4,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        alignItems: "center",
        justifyContent: "center",
      },
})