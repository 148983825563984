import { StyleSheet, View, Text, TouchableOpacity, Image } from "react-native"
import ModeButton from "./ModeButton"
import { useContext } from "react"
import { DonatePageContext } from "../context/DonatePageContext"
import { useNavigation } from "@react-navigation/native"
import { GlobalContext } from "../../../services/GlobalContext"

export default function MainHeader({ showButtons, goToHome }) {
    
    const context = useContext(DonatePageContext)
    const { goBack, navigate } = useNavigation()
    const { settings } = useContext(GlobalContext)

    const type = showButtons && context ? context.type : null

    const styles = StyleSheet.create({
        headerContanier: {
            backgroundColor: settings.settings[0].theme_color,
            height: 50,
            flexDirection: 'row',
            justifyContent: 'space-between',
            elevation: 5,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,
        },
        titleContainer: {
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            height: 50,
            paddingLeft: 20,
        },
        iconContainer: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: 50,
            padding: 20,
            width: '30%',
        },
        buttonContainer: {
            flexDirection: 'row',
            elevation: 5,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,
        }
    })

    return (
        <View>
            {settings && (
                            <View style={styles.headerContanier}>
                            <View style={styles.titleContainer}>
                                <TouchableOpacity onPress={() => {
                                    if (goToHome) {
                                        navigate('Home')
                                        return
                                    }
                                    goBack()
                                }}>
                                    <Image
                                        style={{width:22, height:22, resizeMode:'contain', tintColor:'white'}}
                                        source={{uri:'https://cdn-icons-png.flaticon.com/512/3114/3114883.png'}}
                                    />
                                    
                                </TouchableOpacity>
                                <Text style={{ marginLeft: 10, color:'#fff' }}>
                                {settings.settings[0].site_name}
                                </Text>
                            </View>
                            <View style={styles.iconContainer}>
                                <TouchableOpacity>
                                <Image
                                    style={{width:22, height:22, resizeMode:'contain', tintColor:'white'}}
                                    source={{uri:'https://cdn-icons-png.flaticon.com/512/1828/1828885.png'}}
                                />
                                </TouchableOpacity>
            
                                <TouchableOpacity>
                                <Image
                                        style={{width:22, height:22, resizeMode:'contain', tintColor:'white'}}
                                        source={{uri:'https://cdn-icons-png.flaticon.com/512/78/78931.png'}}
                                />
                                </TouchableOpacity>
                            </View>
                        </View>
            )}

            {showButtons && (
                <View style={styles.buttonContainer}>
                    <ModeButton
                        value="TECLADO"
                        isSelected={type === 'TECLADO'}
                    />
                    <ModeButton
                        value="PRODUTOS"
                        isSelected={type === 'PRODUTOS'}
                    />
                </View>
            )}
        </View>
    )
}


