import { StyleSheet, View, ScrollView, Dimensions, Text } from "react-native";
import ProductContainer from "./components/ProductContainer";
import { useState, useEffect } from "react";

export default function Products() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const updateLayout = () => {
      const screenWidth = Dimensions.get("window").width;
      setIsSmallScreen(screenWidth < 768); // Definindo o breakpoint para telas pequenas
    };

    updateLayout();

    const subscription = Dimensions.addEventListener("change", updateLayout);

    return () => {
      subscription?.remove();
    };
  }, []);

  const styles = StyleSheet.create({
    mainContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 80,
      width: "100%",
    },
    sectionWrapper: {
      marginTop:40,
      flexDirection: isSmallScreen ? "column" : "row", // Se for tela pequena, vai em coluna, senão em linha
      justifyContent: "space-evenly", // Distribui os items uniformemente
      alignItems: "center", // Centraliza os itens no eixo transversal
      width: "100%",
      paddingHorizontal: 20, // Adiciona um padding lateral para melhor espaçamento
    },
    sectionContainer: {
      marginVertical: 20, // Adiciona espaçamento entre os produtos
      width: isSmallScreen ? "100%" : "35%", // Em telas pequenas ocupa 100%, em grandes 45%
      alignItems: "center", // Centraliza os produtos no contêiner
    },
    headerContainer: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      // marginBottom: 20,
      // marginTop: 30,
    },
    title: {
      color: "#000",
      fontSize: 20,
      fontWeight: "bold",
    },
  });

  return (
    <View style={styles.mainContainer}>
      <ScrollView style={{flex: 1, width:'100%'}}>
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Escolha o valor da sua Doação</Text> 
        </View>
        <View style={styles.sectionWrapper}>
          <View style={styles.sectionContainer}>
            <ProductContainer value={5} />
            <ProductContainer value={20} />
            <ProductContainer value={100} />
            <ProductContainer value={500} />
          </View>
          <View style={styles.sectionContainer}>
            <ProductContainer value={10} />
            <ProductContainer value={50} />
            <ProductContainer value={200} />
            <ProductContainer value={1000} />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
