import { View, Text, StyleSheet } from "react-native";

export default function Section({label, value, isSmallScreen}){

    const styles = StyleSheet.create({
        mainContainer:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginTop:10,
            width:'100%'
        },
    
        valueContainer:{
            width: 120, 
            justifyContent:'center', 
            alignItems:'flex-end',
            paddingRight:10,
        },
    
        wrapper:{
            height:1,
            width:'100%',
            backgroundColor:'#b9a9a9',
        }
        
        
    })

    return(
        <View>
            <View style={styles.mainContainer}>
                <View style={{margin:4}}>
                    <Text style={{color:"#000", marginBottom:10, textAlign:'left', fontSize: isSmallScreen ? 14 : 18}}>
                        {label}
                    </Text>
                </View>

                <View style={styles.valueContainer}>
                    <Text style={{color:"#000", marginBottom:10, fontSize: isSmallScreen ? 14 : 18}}>
                        {value}
                    </Text>
                </View>

            </View>
                <View style={styles.wrapper}><Text></Text></View>
        </View>

    )
}

