import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
//import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
//import { faMoneyBill1 } from "@fortawesome/free-solid-svg-icons";
import { useNavigation } from "@react-navigation/native";
import { useContext } from "react";
import { GlobalContext } from "../../../services/GlobalContext";



export default function ProductContainer({value}){

    const {navigate} = useNavigation()
    const {setValor} = useContext(GlobalContext)

    return(
        <TouchableOpacity style={styles.mainContainer}
        onPress={() => {
            setValor(`${value}`)
            navigate('PaymentTypes')
        }}>
            <View style={{width:'100%'}}>
                {/* <FontAwesomeIcon icon={faMoneyBill1} color="#1A83E3"/> */}
            </View>
            <Text style={{color:'#1A83E3', fontSize:22, fontWeight: 'bold'}}>
                R$ {value},00 reais
            </Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    mainContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        width: '100%',
        height: 100,
        padding: 10,
        borderRadius: 8,
        margin: 5,
        elevation: 3,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    }
})