import { DonatePageProvider } from "./context/DonatePageContext";
import DonatePageContent from "./components/DonatePageContent";


export default function DonatePage(){

    return(
        <DonatePageProvider>
            <DonatePageContent/>
        </DonatePageProvider>
        
    )
}
