import { createContext, useState } from "react"

export const DonatePageContext = createContext()

export function DonatePageProvider({children}){
    const [type, setType] = useState('TECLADO')

    return(
        <DonatePageContext.Provider
        value={{type, setType}}>
            {children}
        </DonatePageContext.Provider>
    )
}