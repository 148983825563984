import { useNavigation } from "@react-navigation/native";
import { Text, TouchableOpacity, StyleSheet, View, Image } from "react-native";


export default function NavigateButton({icon, value, label, text, fontSize, isSmallScreen}){

    const {navigate} = useNavigation()

    function HandleNavigation(){
        navigate(value)
    }

    const styles = StyleSheet.create({
        button: {
            flexDirection:'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '100%',
            minWidth: isSmallScreen ? 350 : 400,
            flex:0.1,
            // maxHeight:200,
            minHeight: isSmallScreen? 150 : 250,
            // height: 160,
            borderRadius: 8,
            // paddingBottom:20,
            // paddingTop:20,
            margin: isSmallScreen ? 8 : 10,
            backgroundColor:'#fff',
            elevation:3,
            shadowColor: '#000',         // Cor da sombra
            shadowOffset: { width: 0, height: 2 },  // Deslocamento da sombra
            shadowOpacity: 0.25,         // Opacidade da sombra
            shadowRadius: 3.84,   
        },
        iconContainer:{
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:'#1A83E3',
            padding:14,
            borderRadius:100,
        }
        
    })

    return(
        <View>
            <TouchableOpacity
            onPress={HandleNavigation}
            style={styles.button}>
                <View style={styles.iconContainer}>
                    {icon}
                </View>
                <View>
                    <Text style={{color:'#1A83E3', fontWeight:'bold', fontSize: isSmallScreen? 20 : 24}}>{label}</Text>
                        <View style={{width:200}}>
                            <Text style={{color:'#1A83E3', fontSize: isSmallScreen? 16 : 18}}>{text}</Text>
                        </View>
                </View>
                <View>
                <Image
                    style={{width: 32, height: 32, resizeMode:'contain', tintColor:'#1A83E3'}}
                    source={{uri:'https://cdn-icons-png.flaticon.com/512/545/545682.png'}}/>
                        
                </View>
            </TouchableOpacity>
        </View>

    )
}

